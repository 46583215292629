/**
 * @author Irakli Kandelaki
 */

import type { Ref } from "vue";
import { computed } from "vue";

/**
 * @summary Split text into different array elements by "/" symbol.
 */

export default (text: string): { splitText: Ref<string[]> } => {


    const splitText = computed(() => {
        const dataTitle: string = text;
        const isSplittable: boolean = dataTitle?.includes("/");
        const splitWords: string[] = [];
        if (isSplittable) {
            splitWords.push(...dataTitle.split('/'))
            return splitWords;
        } else {
            return [dataTitle];
        }
    })

    return {
        splitText
    }

}
